import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {useDispatch} from 'react-redux';

import {fetchAuthData} from '../store/auth/actions';
import {fetchUsersForMenu} from '../store/users/actions';
import {setMediaBuyerId} from '../store/app/actions';
import {role} from '../constants';
import {UserRoles} from '../constants/enums';

const AuthWrapper = ({children}) => {
  const {isAuthenticated, getAccessTokenSilently, user} = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();

        const [permission] = user[role];
        if (permission) {
          dispatch(setMediaBuyerId({
            permission: permission.toLowerCase(),
            name: user.name,
            isAdmin: permission.toLowerCase() === UserRoles.ADMIN
          }));
          if(permission === UserRoles.ADMIN) await dispatch(fetchUsersForMenu(accessToken, permission));
          await dispatch(fetchAuthData(accessToken, user));
        } else {
          history.push('/dashboard/403');
        }
      })();
    }
  }, [isAuthenticated]);

  return <>{children}</>;
};

AuthWrapper.propTypes = {
  children: PropTypes.node
};

const Auth0ProviderWithHistory = ({children}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email offline_access"
      audience={audience}
      useRefreshTokens
    >
      <AuthWrapper>{children}</AuthWrapper>
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node
};

export default Auth0ProviderWithHistory;
